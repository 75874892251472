
@keyframes vueContentPlaceholdersAnimation {
0% {
    transform: translate3d(-30%, 0, 0);
}
100% {
    transform: translate3d(100%, 0, 0);
}
}
.vue-content-placeholders-heading {
  display: flex;
}
[class^="vue-content-placeholders-"] + .vue-content-placeholders-heading {
    margin-top: 20px;
}
.vue-content-placeholders-heading__img {
    position: relative;
    overflow: hidden;
    height: 15px;
    background: #eee;
    width: 60px;
    height: 60px;
    margin-right: 15px;
}
.vue-content-placeholders-is-rounded .vue-content-placeholders-heading__img {
      border-radius: 6px;
}
.vue-content-placeholders-is-centered .vue-content-placeholders-heading__img {
      margin-left: auto;
      margin-right: auto;
}
.vue-content-placeholders-is-animated .vue-content-placeholders-heading__img::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      max-width: 1000px;
      height: 100%;
      background: linear-gradient(to right, transparent 0%, #e1e1e1 15%, transparent 30%);
      animation-duration: 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: vueContentPlaceholdersAnimation;
      animation-timing-function: linear;
}
.vue-content-placeholders-heading__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}
.vue-content-placeholders-heading__title {
    position: relative;
    overflow: hidden;
    height: 15px;
    background: #eee;
    width: 85%;
    margin-bottom: 10px;
    background: #ccc;
}
.vue-content-placeholders-is-rounded .vue-content-placeholders-heading__title {
      border-radius: 6px;
}
.vue-content-placeholders-is-centered .vue-content-placeholders-heading__title {
      margin-left: auto;
      margin-right: auto;
}
.vue-content-placeholders-is-animated .vue-content-placeholders-heading__title::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      max-width: 1000px;
      height: 100%;
      background: linear-gradient(to right, transparent 0%, #e1e1e1 15%, transparent 30%);
      animation-duration: 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: vueContentPlaceholdersAnimation;
      animation-timing-function: linear;
}
.vue-content-placeholders-heading__subtitle {
    position: relative;
    overflow: hidden;
    height: 15px;
    background: #eee;
    width: 90%;
}
.vue-content-placeholders-is-rounded .vue-content-placeholders-heading__subtitle {
      border-radius: 6px;
}
.vue-content-placeholders-is-centered .vue-content-placeholders-heading__subtitle {
      margin-left: auto;
      margin-right: auto;
}
.vue-content-placeholders-is-animated .vue-content-placeholders-heading__subtitle::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      max-width: 1000px;
      height: 100%;
      background: linear-gradient(to right, transparent 0%, #e1e1e1 15%, transparent 30%);
      animation-duration: 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: vueContentPlaceholdersAnimation;
      animation-timing-function: linear;
}
[class^="vue-content-placeholders-"] + .vue-content-placeholders-text {
  margin-top: 20px;
}
.vue-content-placeholders-text__line {
  position: relative;
  overflow: hidden;
  height: 15px;
  background: #eee;
  width: 100%;
  margin-bottom: 10px;
}
.vue-content-placeholders-is-rounded .vue-content-placeholders-text__line {
    border-radius: 6px;
}
.vue-content-placeholders-is-centered .vue-content-placeholders-text__line {
    margin-left: auto;
    margin-right: auto;
}
.vue-content-placeholders-is-animated .vue-content-placeholders-text__line::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 1000px;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, #e1e1e1 15%, transparent 30%);
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: vueContentPlaceholdersAnimation;
    animation-timing-function: linear;
}
.vue-content-placeholders-text__line:nth-child(4n + 1) {
    width: 80%;
}
.vue-content-placeholders-text__line:nth-child(4n + 2) {
    width: 100%;
}
.vue-content-placeholders-text__line:nth-child(4n + 3) {
    width: 70%;
}
.vue-content-placeholders-text__line:nth-child(4n + 4) {
    width: 85%;
}
.vue-content-placeholders-img {
  position: relative;
  overflow: hidden;
  height: 15px;
  background: #eee;
  width: 100%;
  height: 120px;
}
.vue-content-placeholders-is-rounded .vue-content-placeholders-img {
    border-radius: 6px;
}
.vue-content-placeholders-is-centered .vue-content-placeholders-img {
    margin-left: auto;
    margin-right: auto;
}
.vue-content-placeholders-is-animated .vue-content-placeholders-img::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 1000px;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, #e1e1e1 15%, transparent 30%);
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: vueContentPlaceholdersAnimation;
    animation-timing-function: linear;
}
[class^="vue-content-placeholders-"] + .vue-content-placeholders-img {
    margin-top: 20px;
}
